<template>

<v-container
    fluid
>
  <v-subheader>
    <h2>
      SQL Query CRUD
    </h2>
  </v-subheader>
  <v-row>
    <v-col
      dense
    >
      <v-card
          v-show="!detailMode"
      >
        <v-data-table
            :headers="headers"
            :items="items"
            mobile-breakpoint="800"
            class="elevation-0">
          <template v-slot:item.actions="{ item }">
            <div class="text-truncate">
              <v-icon
                  small
                  class="mr-2"
                  @click="showEditDialog(item)"
              >
                mdi-pencil
              </v-icon>
              <v-icon
                  small
                  @click="deleteItem(item)"
              >
                mdi-delete
              </v-icon>
            </div>
          </template>
          <template v-slot:item.details="{ item }">
            <div class="text-truncate" style="width: 180px">
              {{item.Details}}
            </div>
          </template>
          <template v-slot:item.url="{ item }">
            <div class="text-truncate" style="width: 180px">
              <a :href="item.URL" target="_new">{{item.URL}}</a>
            </div>
          </template>
        </v-data-table>


        <portal to="rightDrawer">
          <v-list-item>
            <v-btn
                tile
                color="black"
                class="white--text"
                block
                dense
                small
                :disabled="detailMode"
                @click="showEditDialog()"
            >
              New
<!--              <v-icon small>mdi-plus-circle-outline</v-icon>-->
            </v-btn>
          </v-list-item>

          <v-list-item
          >
            <v-btn
                tile
                color="black"
                class="white--text"
                block
                dense
                small
                :disabled="!detailMode"
                @click="saveItem(editedItem)"
            >
              Save
<!--              <v-icon small>mdi-content-save</v-icon>-->
            </v-btn>
          </v-list-item>

          <v-list-item
              color="red"
          >
            <v-btn
                tile
                color="black"
                class="white--text"
                block
                dense
                small
                :disabled="!detailMode"
                @click="showEditDialog()"
            >
              Cancel
<!--              <v-icon small>mdi-close-circle-outline</v-icon>-->
            </v-btn>
          </v-list-item>
        </portal>
      </v-card>


      <sql-query-detail
          v-show="detailMode"
          :editedItem="editedItem"
          @ready="payload => detailIsReady = payload"
      >
      </sql-query-detail>
    </v-col>
  </v-row>


  <go-confirm-dialog
    ref="confirm"
  ></go-confirm-dialog>
</v-container>


</template>

<script>
import api from '../api/backend'
import SqlQueryDetail from "@/components/SQLQueryDetail";
import GoConfirmDialog from "@/components/ammo/goConfirmDialog";
import { notifier, levels } from '../mixins/notifier'

export default {
  name: "SQLQueryCRUD",

  mixins: [
      notifier
  ],

  components: {
    SqlQueryDetail,
    GoConfirmDialog,
  },

  data: () => ({
    inProgress: false,
    inLoading: false,

    headers: [
      { text: "Id", value: "id" },
      { text: "Slug", value: "slug" },
      { text: 'Action', value: 'actions', sortable: false },
    ],
    items: [],

    detailMode: false,
    detailIsReady: false,

    deleteDialogDetails: {
      object: "sql-queries",
      objectDescription: "SQL Queries",
      deferredDeletion: false,
    },

    editedItem: {}
  }),

  mounted() {
    this.loadItems()
  },

  methods: {
    showEditDialog(item) {
      this.editedItem = item || {}
      this.detailMode = ! this.detailMode
    },

    loadItems() {
      this.items = []

      api.getSQLQueries()
          .then(response => {
              this.items = response.data['_embedded']['sQLQueryList']
          }).catch((error) => {
            console.log(error)
            notifier.error(error);
          })
    },

    /**
     * This is used for both creating and updating API records the default method is POST for creating a new item
     */
    saveItem(item) {
      this.$refs.childForm.touch()


      /* this is used for both creating and updating API records
         the default method is POST for creating a new item */

      let id = item.id

      // airtable API needs the data to be placed in fields object
      let data = {
        fields: item
      }

      if (id) {
        // must remove id from the data for airtable patch to work
        delete data.fields.id
      }

      // save the record
      api.saveSQLQuery(id, item)
          .then((response) => {
            if (response.data && response.data.id) {
                // console.log(response.data)

                // add new item to state
                this.editedItem.id = response.data.id

                if (!id) {
                  // add the new item to items state
                  this.items.push(this.editedItem)
                }
                this.editedItem = {}
          }
            this.detailMode = !this.detailMode
          }).catch((error) => {
            console.log(error)
            notifier.error(error);
          })

    },

    deleteItem(item) {
      let id = item.id
      let idx = this.items.findIndex(item => item.id === id)


      //
      // Deprecated standard confirmation dialog
      //
      // if (confirm(`Code delete: Object: SQL Query Delete key: ${item.id} Description: ${item.slug}?`)) {
      //   api.deleteSQLQuery(id)
      //       .then((response) => {
      //         this.items.splice(idx, 1)
      //       }).catch((error) => {
      //         console.log(error)
      //       })
      // }


      this.$refs["confirm"].openDelete(
              'Delete code',
              {
                color: 'red',
                width: 290,
              },
              this.deleteDialogDetails.object,
              this.deleteDialogDetails.objectDescription,
              item.id,
              item.slug,
              this.deferredDeletion
          ).then((confirm) => {
            if (confirm) {
              api.deleteSQLQuery(id)
                    .then((response) => {
                      this.items.splice(idx, 1)
                    }).catch((error) => {
                      console.log(error)
                      notifier.error(error);
                    })
            }
          })
    },
  }
}
</script>

<style scoped>

</style>