<!--
  version 1.0.0
-->
<template>
<!--  <v-container v-show="alerts.length">-->
<!--    <v-row>-->
<!--      <v-col>-->
<!--        <v-alert v-for="(item, index) in alerts"-->
<!--                 :key="`${renderKey}-${index}`"-->
<!--            dense-->
<!--            outlined-->
<!--            :type="item.type"-->
<!--            @input="dismiss(index)"-->
<!--            dismissible-->
<!--        >-->
<!--            {{ item.text }}-->
<!--        </v-alert>-->
<!--      </v-col>-->
<!--    </v-row>-->
<!--  </v-container>-->

  <v-snackbar
      v-model="snackbar"
      :timeout="timeout"
      :color="color"
      outlined
  >
    <v-icon
        :color="color"
    >
      {{ icon }}
    </v-icon>
        {{ item.text }}
    <template v-slot:action="{ attrs }">
      <v-btn
          :color="color"
          text
          v-bind="attrs"
          @click="dismiss(index)"
      >
        {{ $t("Close") }}
<!--        <v-icon-->
<!--            :color="color"-->
<!--        >-->
<!--          mdi-close-circle-outline-->
<!--        </v-icon>-->
      </v-btn>
    </template>
  </v-snackbar>
</template>

<script>
import * as type from '../../store/mutationTypes/types';


export default {
  name: "go-notification",

  data: () => ({
    snackbar: false,
    color: '',
    icon: '',


    timeout: 2000,

    index: -1,
    item: {},
  }),

  computed: {
    alerts() {
      return this.$store.getters['notifications/alerts']
    }
  },

  watch: {
    /**
     * Link to computed property so that we know when a store value has changed.
     * @param newValue
     * @param oldValue
     */
    alerts(newValue, oldValue) {
      this.showLastAlert(newValue)
    }
  },

  methods: {
    showLastAlert() {
      const enabledAlerts = this.$store.getters['notifications/alerts'].filter(item => item.enable === true)

      if (enabledAlerts.length !== 0) {
        this.snackbar = true

        this.index = enabledAlerts.length - 1
        this.item = enabledAlerts.pop()

        // info, success, warning, error
        this.color = this.item.type

        switch (this.item.type) {
          case 'info':
            this.icon = "mdi-information-outline"
            break
          case 'success':
            this.icon = "mdi-check-circle-outline"
            break
          case 'warning':
            this.icon = "mdi-alert-outline"
            break
          case 'error':
            this.icon = "mdi-alert-circle-outline"
            break
        }

        //this.item.type
      } else {
        this.snackbar = false

        this.index = -1
        this.item = {}

        this.color = ''
        this.icon = ''
      }
      return this.index
    },

    dismiss(index) {
      this.snackbar = false

      store.dispatch({
        type: type.RemoveAlert,
        index: index
      })
    }
  }
}
</script>

<style scoped>
</style>