/**
 * Auth Module
 *
 * @version 1.0.0
 */


import * as type from "@/store/mutationTypes/types";
import api from "@/api/backend";


// initial state
const state = () => ({
    status: '',
    token: localStorage.getItem('token') || '',
    user : {},
})

// getters
const getters = {
    isLoggedIn: state => !!state.token,
    authStatus: state => state.status,
    user: state => state.user,
}

// actions
const actions = {
    login({ commit }, user) {
        return new Promise((resolve, reject) => {

            // Commit auth request
            commit(type.AuthRequest)

            api.login()
                .then(response => {
                    const token = response.data.api_token
                    const user = response.data


                    // Put the token in local storage
                    localStorage.setItem('token', token)


                    // Configure axios default headers
                    // axios.defaults.headers.common['Authorization'] = token


                    // Commit auth success
                    commit(type.AuthSuccess, { user, token})
                    resolve(response)
                })
                .catch(err => {
                    commit(type.AuthError)


                    // Remove the token form local storage
                    localStorage.removeItem('token')


                    reject(err)
                })
        })
    }
}

// mutations
const mutations = {
    authRequest(state) {
        state.status = 'loading'
    },

    authSuccess(state, payload) {
        state.status = 'success'
        state.token = payload.token
        state.user = payload.user
    },

    authError(state) {
        state.status = 'error'
    },

    logout(state){
        state.status = ''
        state.token = ''
    },
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}