var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('app-menu',{key:"notifications-menu",attrs:{"close-on-content-click":false,"max-width":_vm.maxWidth,"bottom":"","content-class":"overflow-hidden","left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var attrs = ref.attrs;
var on = ref.on;
return [_c('app-tooltip-btn',_vm._g(_vm._b({attrs:{"path":"notifications"},scopedSlots:_vm._u([{key:"icon",fn:function(){return [_c('v-badge',{attrs:{"value":_vm.unread.length,"color":"red","dot":"","left":"","overlap":""}},[_c('v-icon',{staticClass:"mx-1",domProps:{"textContent":_vm._s(("mdi-bell-" + (_vm.unread.length === 0 ? 'outline' : 'ring-outline')))}})],1)]},proxy:true}],null,true)},'app-tooltip-btn',attrs,false),on))]}}]),model:{value:(_vm.menu),callback:function ($$v) {_vm.menu=$$v},expression:"menu"}},[_c('v-toolbar',{staticClass:"pr-5",attrs:{"width":_vm.maxWidth,"flat":"","short":""}},[_c('v-btn',{staticClass:"px-2 ml-n1",attrs:{"disabled":_vm.archived ? _vm.unread.length < 1 : _vm.read.length < 1,"small":"","text":""},on:{"click":function($event){_vm.archived = !_vm.archived}}},[_c('i18n',{attrs:{"path":_vm.archived ? 'unread' : 'read'},scopedSlots:_vm._u([{key:"number",fn:function(){return [_c('strong',{domProps:{"textContent":_vm._s(_vm.archived ? _vm.unread.length : _vm.read.length)}})]},proxy:true}])})],1),_c('v-spacer'),(_vm.marked)?_c('app-tooltip-btn',{attrs:{"disabled":_vm.done,"icon":_vm.marked.icon,"path":("marked-" + (_vm.marked.path)),"small":""},on:{"click":_vm.toggleAll}}):_vm._e()],1),_c('v-divider'),_c('v-responsive',{staticClass:"overflow-y-scroll",attrs:{"max-height":"320"}},[(_vm.done)?_c('div',{staticClass:"py-8 text-center text-subtitle-1"},[_c('i18n',{attrs:{"path":"done","tag":"p"}}),_c('v-icon',{attrs:{"color":"grey lighten-2","size":"96"}})],1):_vm._l((_vm.filtered),function(ref){
          var created_at = ref.created_at_formated;
          var metadata = ref.metadata;
          var slug = ref.slug;
          var title = ref.title;
return _c('v-list-item',{key:slug,attrs:{"ripple":false},on:{"click":function($event){return _vm.select(slug)}}},[_c('v-list-item-content',[_c('div',{staticClass:"grey--text text--darken-1 text-caption font-weight-light text-uppercase",domProps:{"textContent":_vm._s(created_at)}}),_c('v-list-item-title',{staticClass:"text-subtitle-1",domProps:{"textContent":_vm._s(((metadata.emoji) + " " + title))}})],1),_c('v-list-item-action',[_c('v-btn',{attrs:{"ripple":false,"icon":""},on:{"click":function($event){$event.stopPropagation();$event.preventDefault();return _vm.toggle(slug)}}},[_c('v-icon',{domProps:{"textContent":_vm._s(_vm.marked.icon)}})],1)],1)],1)})],2)],1)}
var staticRenderFns = []

export { render, staticRenderFns }