/**
 * @verion 1.0.0
 * @See https://dev.to/stephannv/how-to-create-a-global-snackbar-using-nuxt-vuetify-and-vuex-1bda
 */

import store from '@/store/index';
import * as type from '@/store/mutationTypes/types';


export const levels = {
    INFO: 'info',
    SUCCESS: 'success',
    WARNING: 'warning',
    ERROR: "error"
}

export const notifier = {
    add(level, text) {
        console.log('add alert')
        store.dispatch({
            type: "notifications/" + type.AddAlert,
            level,
            text
        }).then(r => {})
    },

    error(error) {
        let level = levels.ERROR
        let text = error.message


        if (error.response && error.response.data.message) {
            text = error.response.status + ' ' + error.response.data.message
        }

        store.dispatch({
            type: "notifications/" + type.AddAlert,
            level,
            text
        }).then(r => {})
    }
}
