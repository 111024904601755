<!--
  version 1.0.0

  date: 2020-11-13
-->
<template>
  <v-app-bar
      app
      clipped-right
      dark
  >
    <v-app-bar-nav-icon
        @click.stop="drawerBubbleUp">
    </v-app-bar-nav-icon>
    <v-toolbar-title>{{ applicationName }}</v-toolbar-title>
    <v-spacer></v-spacer>


    <v-text-field
        class="mx-2 mx-md-4"
        style="max-width: 450px;"
        solo
        dense
        hide-details
        label="Search"
        clearable
        prepend-inner-icon="search">
    </v-text-field>
    <v-spacer></v-spacer>



    <go-grupo-valco-apps>
    </go-grupo-valco-apps>


    <!--    <v-btn icon>-->
    <!--      <v-icon>mdi-bell</v-icon>-->
    <!--    </v-btn>-->
    <go-notifications-menu></go-notifications-menu>


    <go-grupo-valco-account></go-grupo-valco-account>

    <v-app-bar-nav-icon
        @click.stop="drawerRightBubbleUp">
    </v-app-bar-nav-icon>
  </v-app-bar>
</template>

<script>
import GoGrupoValcoApps from "@/components/ammo/goGrupoValcoApps"
import GoGrupoValcoAccount from "@/components/ammo/goGrupoValcoAccount"
import GoNotificationsMenu from "@/components/ammo/goNotificationsMenu"


export default {
  name: 'go-application-bar',

  components: {
    GoGrupoValcoApps,
    GoGrupoValcoAccount,
    GoNotificationsMenu,
  },

  props: {
    applicationName: {
      type: String,
      required: true
    },
  },

  data: () => ({
    inProgress: false,
    inLoading: false,
  }),

  created() {
  },

  methods: {
    drawerBubbleUp(payload) {
      this.$emit('drawerClick', payload)
    },

    drawerRightBubbleUp(payload) {
      this.$emit('drawerRightClick', payload)
    },

    appsMenuBubbleUp(payload) {
      this.$emit('appsMenuBubbleUp', payload)
    },

    notificationsMenuBubbleUp(payload) {
      this.$emit('notificationsMenuBubbleUp', payload)
    },

    accountMenuBubbleUp(payload) {
      this.$emit('accountMenuBubbleUp', payload)
    },
  }
}
</script>