<template>
  <v-navigation-drawer
      :value="right"
      @input="rightBubbleUp"
      fixed
      right
      temporary
  ></v-navigation-drawer>
</template>

<script>
    import api from '../api/backend'
    import i18n from '../plugins/i18n'
    import VueEasySession from "vue-easysession";

    export default {
        props: {
            right: {
              type: Boolean,
              required: true,
            },
        },

        data: () => ({
            inProgress: false,
            inLoading: false,

            rightData: [],
        }),

        created() {
        },

        methods: {
          rightBubbleUp(payload) {
            this.$emit('input', payload)
          },
        }
    }
</script>