<!--
  version 1.0.0
-->
<template>
  <v-dialog v-model="dialog" :max-width="options.width" :style="{ zIndex: options.zIndex }" @keydown.esc="cancel">
    <v-card>
      <v-toolbar dark :color="options.color" dense flat>
        <v-toolbar-title class="white--text">{{ title }}</v-toolbar-title>
      </v-toolbar>

      <template v-if="type === 'confirm'">
        <v-card-text v-show="!!message" class="pa-4">{{ message }}</v-card-text>
      </template>

      <template v-if="type === 'delete'">
        <v-card-text>
          <v-row>
            <v-col cols="12" sm="4">
              <v-text-field
                  :value="deleteDetails.object"
                  :label="labels.delete.object"
                  readonly
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="8">
              <v-text-field
                  :value="deleteDetails.objectDescription"
                  label=""
                  readonly
              >
              </v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12">
              <v-text-field
                  :value="deleteDetails.deleteKey"
                  :label="labels.delete.deleteKey"
                  readonly
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12">
              <v-text-field
                  :value="deleteDetails.deleteKeyDescription"
                  :label="labels.delete.deleteKeyDescription"
                  readonly
              >
              </v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12">
              <v-switch
                  :label="labels.delete.deferredDeletion"
                  disabled
              >
              </v-switch>
            </v-col>
          </v-row>
        </v-card-text>
      </template>

      <v-card-actions class="pt-0">
        <v-spacer></v-spacer>
        <v-btn color="primary darken-1" text @click.native="agree">{{ labels.ok }}</v-btn>
        <v-btn color="grey" text @click.native="cancel">{{ labels.close }}</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>


/**
 * Vuetify Confirm Dialog component
 *
 * Insert component where you want to use it:
 * <confirm ref="confirm"></confirm>
 *
 * Call it:
 * this.$refs.confirm.open('Delete', 'Are you sure?', { color: 'red' }).then((confirm) => {})
 * Or use await:
 * if (await this.$refs.confirm.open('Delete', 'Are you sure?', { color: 'red' })) {
 *   // yes
 * }
 * else {
 *   // cancel
 * }
 *
 * Alternatively you can place it in main App component and access it globally via this.$root.$confirm
 * <template>
 *   <v-app>
 *     ...
 *     <confirm ref="confirm"></confirm>
 *   </v-app>
 * </template>
 *
 * mounted() {
 *   this.$root.$confirm = this.$refs.confirm.open
 * }
 *
 * @see
 * https://gist.github.com/eolant/ba0f8a5c9135d1a146e1db575276177d
 */
export default {
  name: 'go-confirm-dialog',

  data: () => ({

    labels: {
      delete: {
        object: "Object",
        deleteKey: "Delete key",
        deleteKeyDescription: "Description",
        deferredDeletion: "Deferred deletion",
      },
      ok: "OK",
      close: "Close page",
    },



    type: '',           // confirm, delete
    dialog: false,
    resolve: null,
    reject: null,
    message: null,
    title: null,

    options: {
      color: 'primary',
      width: 290,
      zIndex: 200
    },

    deleteDetails: {
      object: '',
      objectDescription: '',
      deleteKey: '',
      deleteKeyDescription: '',
      deferredDeletion: false,
    }

  }),
  methods: {
    open(title, message, options) {
      this.type = 'confirm'
      this.dialog = true
      this.title = title


      this.message = message


      this.options = Object.assign(this.options, options)
      return new Promise((resolve, reject) => {
        this.resolve = resolve
        this.reject = reject
      })
    },

    openDelete(title, options, object, objectDescription, deleteKey, deleteKeyDescription, deferredDeletion) {
      this.type = 'delete'

      this.dialog = true
      this.title = title


      this.deleteDetails.object = object
      this.deleteDetails.objectDescription = objectDescription
      this.deleteDetails.deleteKey = deleteKey
      this.deleteDetails.deleteKeyDescription = deleteKeyDescription
      this.deleteDetails.deferredDeletion = deferredDeletion


      this.options = Object.assign(this.options, options)
      return new Promise((resolve, reject) => {
        this.resolve = resolve
        this.reject = reject
      })
    },

    agree() {
      this.resolve(true)
      this.dialog = false
    },
    cancel() {
      this.resolve(false)
      this.dialog = false
    }
  }
}
</script>