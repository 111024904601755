<!--
  version 1.0.0
-->
<template>
<!--  <v-container-->
<!--  >-->
<!--    <v-row align="center"-->
<!--           justify="center">-->
<!--      <v-icon-->
<!--        large-->
<!--      >-->
<!--        {{ app.icon }}-->
<!--      </v-icon>-->
<!--    </v-row>-->
<!--    <v-row align="center"-->
<!--           justify="center">-->
<!--      <v-col>-->
<!--            {{ app.name }}-->
<!--      </v-col>-->
<!--    </v-row>-->
<!--  </v-container>-->

<!--  <div class="d-flex flex-column align-center">-->
<!--    <div>-->
<!--      <v-icon-->
<!--        large-->
<!--      >-->
<!--        {{ app.icon }}-->
<!--      </v-icon>-->
<!--    </div>-->
<!--    <div>-->
<!--      {{ app.name }}-->
<!--    </div>-->
<!--  </div>-->

    <v-list
        width="95px"
    >
    <v-list-item
        :link="app.type === 'static-url'"
        :href="app.value"
        :target="app.target"
        :disabled="!app.enabled"
    >
      <v-list-item-content
          class="text-center ma-0 pa-0"
      >
        <v-list-item-avatar>
          <v-icon
            large
          >
            {{ app.icon }}
          </v-icon>
        </v-list-item-avatar>
        <v-list-item-title
          class
        >
          {{ app.name }}
        </v-list-item-title>
<!--        <v-list-item-subtitle>Founder of Vuetify</v-list-item-subtitle>-->
      </v-list-item-content>
    </v-list-item>
    </v-list>
</template>

<script>
export default {
  name: 'go-grupo-valco-app',

  props: {
    app: Object,
  },
}
</script>

<style scoped>
.v-list-item__title {
  font-size: .8125rem;
}
</style>