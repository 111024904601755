<!--
  version 1.0.0
-->
<template>
  <v-menu offset-y>
    <template v-slot:activator="{ on, attrs }">
      <v-btn icon
             v-bind="attrs"
             v-on="on"
      >
        <v-avatar>
          <v-icon>mdi-account</v-icon>
<!--          <span class="white&#45;&#45;text headline">RG</span>-->
        </v-avatar>
      </v-btn>
    </template>

    <v-card>
      <v-list>
        <v-list-item>
          <v-list-item-avatar>
            <img
                src="https://cdn.vuetifyjs.com/images/john.jpg"
                alt="John"
            >
          </v-list-item-avatar>

          <v-list-item-content>
            <v-list-item-title>{{ user.name }}</v-list-item-title>
            <v-list-item-subtitle>{{ user.email }}</v-list-item-subtitle>
          </v-list-item-content>

          <v-list-item-action>
            <v-btn
                :class="fav ? 'red--text' : ''"
                icon
                @click="fav = !fav"
            >
              <v-icon>mdi-heart</v-icon>
            </v-btn>
          </v-list-item-action>
        </v-list-item>
      </v-list>

      <v-divider></v-divider>

      <v-list>
        <v-list-item>
          <v-list-item-action>
            <v-switch
                v-model="message"
                color="purple"
            ></v-switch>
          </v-list-item-action>
          <v-list-item-title>Enable messages</v-list-item-title>
        </v-list-item>

        <v-list-item>
          <v-list-item-action>
            <v-switch
                v-model="hints"
                color="purple"
            ></v-switch>
          </v-list-item-action>
          <v-list-item-title>Enable hints</v-list-item-title>
        </v-list-item>
      </v-list>

      <v-card-actions>
        <v-spacer></v-spacer>

        <v-btn
            text
            @click="menu = false"
        >
          Cancel
        </v-btn>
        <v-btn
            color="primary"
            text
            @click="menu = false"
        >
          Save
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-menu>
</template>

<script>
export default {
  name: 'go-grupo-valco-account',

  data: () => ({
    inProgress: false,
    inLoading: false,

    fav: true,
    menu: false,
    message: false,
    hints: true,


    items: []
  }),

  created() {
  },

  computed: {
    user() {
      return this.$store.getters['auth/user']
    }
  },

  methods: {
  },
}
</script>