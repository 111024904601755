import Vue from 'vue';
import VueI18n from 'vue-i18n';

Vue.use(VueI18n);

const messages = {
    'en': {
        application: 'Application',
        login: 'Sign in',
        register: 'Sign up',
        email: 'Email',
        enterYourEmail: 'Enter your email address',
        password: 'Password',
        confirmPassword: 'Confirm password',
        title: 'Title',
        projectName: 'Project name',
        companyName: 'Company name',
        getStarted: 'Get started',
        companyInfo: 'Company info',
        contacts: 'Contact us',
        remember: 'Remember me?',
        forgotPassword: 'Forgot password?',
        dontHaveAccount: 'Dont\' have an account?',
        haveAccount: 'Already have an account?',
        emailRequired: 'Email is required.',
        emailNotValid: 'Email is not valid.',
        passwordRequired: 'Password is required.',
        passwordFormat: 'Password should contains min 8 characters including a number and a uppercase letter.',
        passwordMatch: 'The password you entered don\'t match.',
        connectionLost: 'The connection to the server has been lost.',
        sendPasswordUpdateEmail: 'Send password update email',
        updatePassword: 'Update password',
        returnToLogin: 'Return to login',
        close: 'Close',
        home: 'Home',
        contact: 'Contacts',
        logout: 'Logout',
        language: "Language",
        success: "Success",
        error: "Error",


        description: "Description",
        color: "Color",
        workOrder: "Work order",

        quantityShort: "Qty",
        modelShort: "Mod",
        lengthShort: "Leng.",
        widthShort: "Widt.",
        thicknessShort: "Thic.",
        plannedQuantityShort: "Exp.qty",
        operationNumberShort: "Op. no.",

        // Bottom navigation operations
        printLabelShort: "Label",
        operationTrackShort: "Track",
        pairTagShort: "Pair",

        // --- Notification menu component ---
        // @see https://github.com/vuetifyjs/vuetify/blob/master/packages/docs/src/i18n/messages/en.json
        "marked-read": "Mark all read",
        "marked-unread": "Mark all unread",
        "done": "All done",
        "unread": "View Unread ({number})",
        "read": "View Read ({number})",
    },
    'pt': {
        application: 'Aplicação',
        login: 'Iniciar sessão',
        register: 'Registar',
        email: 'E-mail',
        enterYourEmail: 'Insira o seu endereço de email',
        password: 'Palavra-chave',
        confirmPassword: 'Confirmar palavra-chave',
        title: 'Título',
        projectName: 'Nome do Projeto',
        companyName: 'Nome da empresa',
        getStarted: 'Iniciar',
        companyInfo: 'Informação da empresa',
        contacts: 'Contactar-nos',
        remember: 'Lembrar de mim?',
        forgotPassword: 'Esqueceu palavra-chave?',
        dontHaveAccount: 'Não tem uma conta?',
        haveAccount: 'Já tem uma conta?',
        emailRequired: 'O e-mail é obrigatória.',
        emailNotValid: 'O e-mail não é válido.',
        passwordRequired: 'Palavra-chave obrigatória.',
        passwordFormat: 'A palavra-chave deve conter no mínimo 8 caracteres, incluindo um número e uma letra maiúscula.',
        passwordMatch: 'A palavra-chave que você digitou não confere.',
        connectionLost: 'A conexão com o servidor foi perdida.',
        sendPasswordUpdateEmail: 'Enviar e-mail de atualização de palavra-chave',
        updatePassword: 'Actualizar  palavra-chave',
        returnToLogin: 'Voltar ao inicio de sessão ',
        close: 'Fechar',
        home: 'Home',
        contact: 'Контакты',
        logout: 'Terminar sessão',
        language: "Língua",
        success: "Sucesso",
        error: "Erro",


        description: "Descrição",
        color: "Côr",
        workOrder: "Ordem de fabrico",

        quantityShort: "Qtd.",
        modelShort: "Mod",
        lengthShort: "Comp.",
        widthShort: "Larg.",
        thicknessShort: "Espe.",
        plannedQuantityShort: "Qtd.pla",
        operationNumberShort: "Op.",


        // Bottom navigation operations
        printLabelShort: "Etiqueta",
        operationTrackShort: "Seguir",
        pairTagShort: "Parear",

        // --- Notification menu component ---
        "marked-read": "Marcar todos como lidos",
        "marked-unread": "Marcar todos como não lidos",
        "done": "Tudo feito",
        "unread": "Ver não lidas ({number})",
        "read": "Ver lidas ({number})",
    }
};

const i18n = new VueI18n({
    locale: localStorage.getItem('locale') || navigator.language.split('-')[0],
    fallbackLocale: 'en',
    messages,
});

export default i18n;