// Packages
import Vue from 'vue'
import App from './App.vue'
import router from './router'
import Vuetify from 'vuetify'
import 'vuetify/dist/vuetify.min.css'
import 'vue-material-design-icons/styles.css';
import vuetify from './plugins/vuetify';
import VueCookie from 'vue-cookie';
import VueEasySession from 'vue-easysession'
import i18n from './plugins/i18n';
import FlagIcon from 'vue-flag-icon';
import Vuelidate from 'vuelidate'
import PortalVue from 'portal-vue'

// Bootstrap
import store from '@/store'


Vue.config.productionTip = false;

// Register plugins
Vue.use(Vuetify);
Vue.use(VueCookie);
Vue.use(Vuelidate);
Vue.use(VueEasySession.install);
Vue.use(FlagIcon);
Vue.use(PortalVue)


new Vue({
    VueCookie,
    router,
    store,
    vuetify,
    VueEasySession,
    i18n,
    render: h => h(App)
}).$mount('#app');
