<template>
  <v-footer
      app
      color="black"
      class="white--text"
  >
    <span>Grupo Valco</span>
    <v-spacer></v-spacer>
    <span>&copy; {{ copyrightYear }}</span>
  </v-footer>
</template>

<script>
    import api from '../api/backend'
    import i18n from '../plugins/i18n'
    import VueEasySession from "vue-easysession";

    export default {
        props: {
        },

        data: () => ({
            inProgress: false,
            inLoading: false,

            copyrightYear: new Date().getFullYear()
        }),

        created() {
        },

        methods: {
        }
    }
</script>