<template>
    <v-container fluid fill-height>
      <v-layout fill-height>
        <v-flex fill-height>

          <v-card
              height='100%'
              class="pa-0"
          >
            <v-card-text style="height:100%; position: relative; padding: 0; margin: 0;">
              <iframe style="height: 100%; width: 100%; border: none; padding: 0;"
                                  src="http://localhost:50014/manufacturing/gantt/chart/operations"
                          >
              </iframe>
            </v-card-text>
          </v-card>
        </v-flex>
      </v-layout>
    </v-container>

</template>

<script>
    import api from '../api/backend'
    import i18n from '../plugins/i18n'
    import VueEasySession from "vue-easysession";

    export default {
        props: {
        },

        data: () => ({
            inProgress: false,
            inLoading: false,
        }),

        created() {
        },

        methods: {
        }
    }
</script>