<template>
    <v-list dense>
      <v-list-item @click.stop="rightBubbleUp">
        <v-list-item-action>
          <v-icon>mdi-exit-to-app</v-icon>
        </v-list-item-action>
        <v-list-item-content>
          <v-list-item-title>Open Temporary Drawer</v-list-item-title>
        </v-list-item-content>
      </v-list-item>


      <portal-target name="rightDrawer" />
    </v-list>
</template>

<script>
    import i18n from '../plugins/i18n'
    import VueEasySession from "vue-easysession";

    export default {
        props: {
        },

        data: () => ({
            inProgress: false,
            inLoading: false,

            drawerRighterData: {
              items: []
            },
        }),

        created() {
          this.getDrawerRighterData()
        },

        methods: {
          getDrawerRighterData() {
            this.drawerRighterData.items = [
              {
                icon: 'mdi-exit-to-app',
                title: 'Open Temporary Drawer'
              },
            ]
          },

          rightBubbleUp(payload) {
            this.$emit('click', payload)
          },
        }
    }
</script>