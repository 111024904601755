<!--
  @see https://github.com/vuetifyjs/vuetify/blob/master/packages/docs/src/components/app/Sheet.vue
-->
<template>
  <v-sheet
      class="overflow-hidden"
      v-bind="{
      outlined: true,
      rounded: true,
      ...$attrs,
    }"
      v-on="$listeners"
  >
    <slot />
  </v-sheet>
</template>

<script>
export default { name: 'AppSheet' }
</script>