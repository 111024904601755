import Vue from 'vue'
import Router from 'vue-router'
import Home from '@/views/Home'
import Registration from '@/components/Registration'
import Login from '@/components/Login'
import Protected from '@/components/Protected'
import ForgotPassword from '@/components/ForgotPassword'
import PasswordUpdate from '@/components/PasswordUpdate'
import RegistrationConfirm from '@/components/RegistrationConfirm'
import VueEasySession from "vue-easysession";
import VueCookie from 'vue-cookie'

import ComplexLayout from '@/components/ComplexLayout'
import ThunderboltManufacturingPlanningGantt from "@/components/ThunderboltManufacturingPlanningGantt";

import SQLQueryCRUD from "@/views/SQLQueryCRUD";

import * as type from "@/store/mutationTypes/types";
import store from './store'


Vue.use(Router);
Vue.use(VueCookie);

const router = new Router({
    mode: 'history',
    routes: [
        { path: '/', component: Home },
        { path: '/sql-queries', component: SQLQueryCRUD },
        { path: '/manufacturing-scheduling/plans', component: ThunderboltManufacturingPlanningGantt },
        { path: '/manufacturing-scheduling/operations', component: ThunderboltManufacturingPlanningGantt },
        { path: '/login', component: Login, meta: { loginPage: true } },
        { path: '/forgot-password', component: ForgotPassword },
        { path: '/password-update/:code', component: PasswordUpdate },
        { path: '/protected', component: Protected, meta: { requiresAuth: true } },
        { path: '/registration-confirm/:code', component: RegistrationConfirm },
        { path: '*', redirect: '/' }
    ]
});

router.beforeEach((to, from, next) => {
    // const session = VueEasySession.getInstance();
    // const requiresAuth = to.matched.some(record => record.meta.requiresAuth);
    // const isLoginPage = to.matched.some(record => record.meta.loginPage);
    // const isAuthenticated = session.exists();
    // const token =  Vue.cookie.get('token');
    // let tokenExist = false;
    // if ((isLoginPage || requiresAuth) && !isAuthenticated && token != null) {
    //     api.checkTokenExist(token).then(response => {
    //         tokenExist = response.data;
    //         if(tokenExist) {
    //             session.start();
    //             session.set('token', token);
    //             router.push('/protected').catch(error => {console.log(error);});
    //         } else {
    //             next();
    //         }
    //     }).catch(error => {
    //         console.log(error);
    //         next();
    //     });
    // } else if (requiresAuth && !isAuthenticated) {
    //     next('/login');
    // } else if (isLoginPage && isAuthenticated) {
    //     router.push('/protected').catch(error => {console.log(error);});
    // } else {
    //     next();
    // }


    // Redirect login
    // console.log(process.env.VUE_APP_SECURITY_OAUTH2_SSO_LOGIN_PATH)

    // userService.login()
    //     .then(response => console.log('login performed'))
    //     .catch(error => console.log('login fail'))




    // if (to.matched.some(record => record.meta.requiresAuth)) {
    //     if (store.getters.isLoggedIn) {
    //         next()
    //         return
    //     }
    //     // Redirect login
    //     console.log(process.env.VUE_APP_SECURITY_OAUTH2_SSO_LOGIN_PATH)
    //
    //
    //
    //     // next('/login')
    //
    //
    // } else {
    //     next()
    // }


    store.dispatch('auth/login', {})
        .then(response => console.log("login"))
        .catch(error => console.log("not login"))
    next()
});

export default router;