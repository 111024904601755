<template>
  <go-left-drawer
      :resolveLocalPrefix="resolveLocalPrefix"
      :items="authorizedItems"
      @click.stop="leftBubbleUp"
  />
</template>

<script>
import { GoLeftDrawerComponent as GoLeftDrawer } from '@gv/ammo-grome';
import { AuroraService } from '@gv/ammo-astra';

export default {
  components: {
    GoLeftDrawer,
  },


  created() {
    this.retrieve();
  },

  data() {
    return {
      auroraService: () => new AuroraService(),
      menu: 'thunderbolt',
      resolveLocalPrefix: 'thunderbolt',
      authorizedItems: [],
    };
  },

  methods: {
    retrieve() {
      this.auroraService()
          .sideBarMenu(this.menu)
          .then(res => {
            console.log(res)
            this.authorizedItems = res;
          })
          .catch(error => {
            this.alertService().showHttpError(this, error.response);
          });
    },

    leftBubbleUp(payload) {
      this.$emit('click', payload)
    },
  }
}
</script>
