/**
 * Notifications Module
 *
 * @version 1.0.0
 */


import * as type from "@/store/mutationTypes/types";

// initial state
const state = () => ({
    alerts: [
        // {
        //     type: "info",
        //     text: "I'm a dense alert with a <strong>type</strong> of info",
        //     enable: true,
        // },
        // {
        //     type: "success",
        //     text: " I'm a dense alert with the <strong>text</strong> prop and a <strong>type</strong> of success",
        //     enable: true,
        // },
        // {
        //     type: "warning",
        //     text: "I'm a dense alert with the <strong>border</strong> prop and a <strong>type</strong> of warning",
        //     enable: true,
        // },
        // {
        //     type: "error",
        //     text: "I'm a dense alert with the <strong>outlined</strong> prop and a <strong>type</strong> of error",
        //     enable: true,
        // },
    ],
})

// getters
const getters = {
    alerts: state => {
        return state.alerts
    }
}

// actions
const actions = {
    addAlert(context, payload) {
        context.commit(type.AddAlert, payload)
    },
    removeAlert(context, payload) {
        context.commit(type.RemoveAlert, payload)
    }
}

// mutations
const mutations = {
    addAlert (state, payload) {
        let level   = payload.level ? payload.level : 'info'
        let text    = payload.text ? payload.text : ''
        let enable  = true


        return state.alerts.push({
            'type': level,
            text,
            enable,
        })
    },

    removeAlert(state, payload) {
        return state.alerts.splice(payload.index, 1)
    }
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}