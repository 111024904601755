<!--
  version 1.0.1
-->
<template>
  <v-menu offset-y>
    <template v-slot:activator="{ on, attrs }">
      <v-btn icon
             v-bind="attrs"
             v-on="on"
      >
        <v-icon>mdi-apps
        </v-icon>
      </v-btn>
    </template>

    <v-card>
      <v-container>
        <v-row
            v-for="i in rows"
            :key="`row-${i}`"
            no-gutters
        >
          <v-col
              v-for="j in ITEMS_PER_ROW"
              :key="`app-${(i - 1) * ITEMS_PER_ROW + (j) }`"
          >
            <go-grupo-valco-app
                v-if = "(i - 1) * ITEMS_PER_ROW + (j) <= items.length"
                :key="`app-${(i - 1) * ITEMS_PER_ROW + (j) }`"
                :app="items[(i - 1) * ITEMS_PER_ROW + (j - 1)]"
            >
            </go-grupo-valco-app>
          </v-col>
        </v-row>
      </v-container>
    </v-card>
  </v-menu>
</template>

<script>
import api from "@/api/aurora"
import GoGrupoValcoApp from "@/components/ammo/goGrupoValcoApp"

export default {
  name: 'go-grupo-valco-apps',

  components: {
    GoGrupoValcoApp
  },

  data: () => ({
    ITEMS_PER_ROW: 3,


    inProgress: false,
    inLoading: false,

    items: []
  }),

  created() {
    this.getApps()
  },

  computed: {
    rows() {
      return Math.ceil(this.items.length / this.ITEMS_PER_ROW)
    }
  },

  methods: {
    getApps() {
      api.getLeft()
          .then(response => {
            this.inProgress = false
            this.inLoading = false
            this.items = response.data
          })
          .catch(error => {
            this.inProgress = false
            this.inLoading = false
          })
    },
  },
}
</script>