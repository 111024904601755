/**
 * Authentication types
 */
export const AuthRequest = 'authRequest'
export const AuthSuccess = 'authSuccess'
export const AuthError = 'authError'
export const Logout = 'logout'


/**
 * Notifications
 */
export const AddAlert = 'addAlert'
export const RemoveAlert = 'removeAlert'
