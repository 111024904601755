// Vue
import Vue from 'vue'
import Vuex from 'vuex'

// Utilities
import pathify from '@/plugins/vuex-pathify'

// Modules
import auth from './modules/auth'
import user from './modules/user'
import notifications from './modules/notifications'
import snackbar from './modules/snackbar'

Vue.use(Vuex)

// store definition
const store = new Vuex.Store({
    modules: {
        auth,
        user,
        notifications,
        snackbar,
    },
    plugins: [pathify.plugin],  // activate plugin
})

store.subscribe(mutation => {
    if (!mutation.type.startsWith('user/')) return

    store.dispatch('user/update', mutation).then()
})

export default store

// export const ROOT_DISPATCH = Object.freeze({ root: true })