<template>
  <div id="app">
    <v-app id="inspire">
      <v-app id="inspire">
        <v-navigation-drawer
            v-model="drawerRight"
            app
            clipped
            right
        >
          <!--
          <v-list dense>
            <v-list-item @click.stop="right = !right">
              <v-list-item-action>
                <v-icon>mdi-exit-to-app</v-icon>
              </v-list-item-action>
              <v-list-item-content>
                <v-list-item-title>Open Temporary Drawer</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list>
          -->
          <thunderbolt-right-drawer
              :right="right"
              @click.stop="right = !right"
          >
          </thunderbolt-right-drawer>
        </v-navigation-drawer>


        <!--
        <v-app-bar
            app
            clipped-right
            color="blue-grey"
            dark
        >
          <v-app-bar-nav-icon @click.stop="drawer = !drawer"></v-app-bar-nav-icon>
          <v-toolbar-title>Toolbar</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-app-bar-nav-icon @click.stop="drawerRight = !drawerRight"></v-app-bar-nav-icon>
        </v-app-bar>
        -->
        <go-application-bar
            :applicationName="applicationName"
            @drawerClick.stop="drawer = !drawer"
            @drawerRightClick.stop="drawerRight = !drawerRight"
            @appsMenuClick=""
            @notificationsMenuClick=""
            @accountMenuClick=""
        >
        </go-application-bar>



        <go-snackbar />

        <v-navigation-drawer
            v-model="drawer"
            app
        >
          <!--
          <v-list dense>
            <v-list-item @click.stop="left = !left">
              <v-list-item-action>
                <v-icon>mdi-exit-to-app</v-icon>
              </v-list-item-action>
              <v-list-item-content>
                <v-list-item-title>Open Temporary Drawer</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list>
          -->
<!--          <go-left-drawer-->
<!--              menu="thunderbolt"-->
<!--              :left="left"-->
<!--              @click.stop="left = !left"-->
<!--          >-->
<!--          </go-left-drawer>-->
          <jhi-navbar-extended></jhi-navbar-extended>
        </v-navigation-drawer>

        <!--
        <v-main>
          <v-container
              class="fill-height"
              fluid
          >
            <v-row
                justify="center"
                align="center"
            >
              <v-col class="shrink">
                <v-tooltip right>
                  <template v-slot:activator="{ on }">
                    <v-btn
                        :href="source"
                        icon
                        large
                        target="_blank"
                        v-on="on"
                    >
                      <v-icon large>mdi-code-tags</v-icon>
                    </v-btn>
                  </template>
                  <span>Source</span>
                </v-tooltip>
              </v-col>
            </v-row>
          </v-container>
        </v-main>
        -->
        <v-main
          fluid
        >
          <go-notification></go-notification>
          <router-view></router-view>
        </v-main>

        <v-navigation-drawer
            v-model="right"
            fixed
            right
            temporary
        >
          <thunderbolt-right
              :right="right"
              @input="right = $event"
          >
          </thunderbolt-right>
        </v-navigation-drawer>


        <!--
        <v-footer
            app
            color="blue-grey"
            class="white--text"
        >
          <span>Vuetify</span>
          <v-spacer></v-spacer>
          <span>&copy; {{ new Date().getFullYear() }}</span>
        </v-footer>
        -->
        <thunderbolt-footer />
      </v-app>
    </v-app>
  </div>

</template>

<script>
  import ThunderboltRightDrawer from "@/components/ThunderboltRightDrawer"
  import ThunderboltFooter from "@/components/ThunderboltFooter"
  import ThunderboltRight from "@/components/ThunderboltRight"


  import GoApplicationBar from "@/components/ammo/goApplicationBar"
  import GoNotification from "@/components/ammo/goNotification"
  import GoSnackbar from "@/components/ammo/goSnackbar"
  import JhiNavbarExtended from "@/app/core/jhi-navbar-extended/jhi-navbar-extended";

  export default {
    name: "App",

    components: {
      JhiNavbarExtended,
      ThunderboltFooter,
      ThunderboltRightDrawer,
      ThunderboltRight,

      GoApplicationBar,
      GoSnackbar,
      GoNotification,
    },

    props: {
      source: String,
    },

    data: () => ({
      applicationName: "Backoffice Admin",

      drawer: null,
      drawerRight: null,
      right: false,
      left: false,

      apps: false,
    }),
}
</script>

<style scoped>

</style>
