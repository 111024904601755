<template>
  <v-card>
    <v-card-title>
      <span v-if="editedItem.id">Edit {{editedItem.id}}</span>
      <span v-else>Create</span>
    </v-card-title>
    <v-card-text>
      <v-row>
        <v-col cols="12" sm="4">
          <v-text-field
              v-model="editedItem.slug"
              label="Slug"
              :error-messages="slugErrors"
              @input="$v.editedItem.slug.$touch()"
              @blur="$v.editedItem.slug.$touch()"
          >
          </v-text-field>
        </v-col>
        <v-col cols="12" sm="8">
          <v-text-field
              label="Datasource"
              disabled
          >
          </v-text-field>
        </v-col>
        <v-col cols="12" sm="12">
          <v-textarea
              v-model="editedItem.value"
              label="Value"
              clearable
              clear-icon="cancel"
          >
          </v-textarea>
        </v-col>
        <v-col cols="12" sm="12">
          <v-textarea
              v-model="editedItem.params"
              label="Parameters"
              clearable
              clear-icon="cancel"
          >
          </v-textarea>
        </v-col>
        <v-col cols="12" sm="12">
          <v-textarea
              v-model="editedItem.usedBy"
              label="Used By"
              clearable
              clear-icon="cancel"
          >
          </v-textarea>
        </v-col>
      </v-row>
    </v-card-text>
<!--    <v-card-actions>-->
<!--      <v-spacer></v-spacer>-->
<!--      <v-btn color="blue darken-1" text @click="cancelBubbleUp()">Cancel</v-btn>-->
<!--      <v-btn color="blue darken-1" text @click="saveBubbleUp(editedItem)">Save</v-btn>-->
<!--    </v-card-actions>-->
  </v-card>
</template>

<script>
    import { validationMixin } from 'vuelidate'
    import { required } from 'vuelidate/lib/validators'


    export default {
      mixins: [validationMixin],
      name: "SqlQueryDetail",

      props: {
          editedItem: {
            type: Object,
            required: true,
          }
      },

      validations: {
        editedItem: {
          slug: {
            required
          }
        }
      },

      data: () => ({
      }),

      computed: {
        slugErrors() {
          const errors = []
          if (!this.$v.editedItem.slug.$dirty) return errors
          !this.$v.editedItem.slug.required && errors.push('Slug is required.')
          return errors
        },

        ready () {
          return !this.$v.$invalid
        }
      },

      watch: {
        ready(valid) {
          this.$emit('ready', valid)
        }
      },

      methods: {
        submit() {
          this.$v.$touch()
        },
      }
    }
</script>